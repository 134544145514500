import {Component} from '@angular/core';
import {Child, Legend, Test, TestData} from 'isophi-pdfs-api';
import {IsophiData} from '../../../server/data.model';
import {BaseReportComponent} from '../../core/components/base-report/base-report.component';

import {emojis, evaluationsText, evaluationsTextZ} from './basic-parent-report.data';

const reportId = 'basicParent';
const documentId = '';

export interface BasicParentData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  test: Test;
  testData: TestData;
  legend: Legend[];
}

@Component({
  selector: 'app-basic-parent-report',
  templateUrl: './basic-parent-report.component.html',
  styleUrls: ['./basic-parent-report.component.scss'],
})
export class BasicParentReportComponent extends BaseReportComponent<BasicParentData> {
  evaluationsText = evaluationsText;
  evaluationsTextZ = evaluationsTextZ;
  emojis = emojis;

  parentUrl = 'https://rozvojditete.cz/';

  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public sortLegend(legend: Legend[]) {
    return legend.sort((a, b) => b.code - a.code);
  }

  public isSlovak(): boolean {
    return this.currentLang === 'sk';
  }
}
