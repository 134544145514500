import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  // inject service in constructor, like "public icons: IconService"
  // then in html use like [class]="icons,EXAMPLE"
  // EXAMPLE = 'fe fe-plus';
  eye = 'fe fe-eye';

  arrowRight = 'fe fe-arrow-right';

  calendar = 'fe fe-calendar';

  chevronRight = 'fe fe-chevron-right';

  chevronLeft = 'fe fe-chevron-left';

  download = 'fe fe-download';

  star = 'fe fe-star';

  helpCircle = 'fe fe-help-circle';

  infoCircle = 'fa fa-info-circle';

  settings = 'fe fe-settings';

  enrollment = 'fe fe-pen-tool';

  plus = 'fe fe-plus';

  menu = 'fe fe-menu';

  // management app menu items icons
  children = 'fe fe-user';

  dashboard = 'fe fe-home';

  phone = 'fe fe-phone';

  mail = 'fe fe-mail';

  notification = 'fe fe-bell';

  chevronDown = 'fe fe-chevron-down';

  search = 'fe fe-search';

  minus = 'fe fe-minus';

  warning = 'fe fe-alert-circle';

  checkCircle = 'fe fe-check-circle';

  verticalDots = 'fe fe-more-vertical';

  arrowDownCircle = 'fe fe-arrow-down-circle';

  horizontalDots = 'fe fe-more-horizontal';

  close = 'fe fe-x';

  bell = 'fe fe-bell';

  printer = 'fe fe-printer';

  circle = 'fe fe-circle';

  check = 'fe fe-check';

  book = 'fe fe-book';

  chevronUp = 'fe fe-chevron-up';

  x = 'fe fe-x';

  volume2 = 'fe fe-volume-2';

  users = 'fe fe-users';

  xCircle = 'fe fe-x-circle';

  clock = 'fe fe-clock';

  messageCircle = 'fe fe-message-circle';

  target = 'fe fe-target';

  playCircle = 'fe fe-play-circle';

  send = 'fe fe-send';

  checkSquare = 'fe fe-check-square';

  edit2 = 'fe fe-edit-2';

  type = 'fe fe-type';

  userPlus = 'fe fe-user-plus';
}
